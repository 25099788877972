var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import theme from '../Theming/DefaultTheme';
var footerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 70,
    color: theme.palette.common.white,
    padding: 12,
    width: '100%',
    bottom: 0, // Ensures the footer stays at the bottom
    backgroundColor: theme.palette.primary.dark,
};
var inlineTextBoxStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center',
};
var footerTextColumnInner = {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    gap: 12,
    width: '100%',
};
var footerLinkStyle = {
    textDecoration: 'none',
    color: 'white',
    cursor: 'pointer',
    transition: 'color 0.3s',
};
var footerLinksContainer = {
    display: 'flex',
    gap: 10,
};
var Footer = function () {
    var navigate = useNavigate();
    var _a = useState({
        loadTime: 0,
    }), componentState = _a[0], setComponentState = _a[1];
    var updateComponentState = function (newComponentState) {
        setComponentState(function (componentState) {
            return __assign(__assign({}, componentState), newComponentState);
        });
    };
    useEffect(function () {
        var loadTime = performance.now();
        updateComponentState({ loadTime: loadTime });
    }, []);
    var handleNavigate = function (path, hash) {
        navigate(path);
        if (hash) {
            window.location.hash = hash;
        }
    };
    return (_jsx("footer", { style: footerStyle, children: _jsx(Grid, { container: true, spacing: 2, width: '100%', children: _jsx(Grid, { size: { lg: 12, xs: 12 }, children: _jsxs(Box, { style: footerTextColumnInner, children: [_jsxs(Typography, { variant: 'body2', children: ["\u00A9 ", new Date().getFullYear(), " Capture Quote"] }), _jsxs(Box, { style: footerLinksContainer, children: [_jsx(Typography, { variant: 'caption', children: _jsx("a", { style: footerLinkStyle, onClick: function () { return handleNavigate('/terms'); }, children: "Terms" }) }), _jsx(Typography, { variant: 'caption', children: _jsx("a", { style: footerLinkStyle, onClick: function () { return handleNavigate('/privacy'); }, children: "Privacy" }) }), _jsx(Typography, { variant: 'caption', children: _jsx("a", { style: footerLinkStyle, onClick: function () { return handleNavigate('/data'); }, children: "Data" }) })] })] }) }) }) }));
};
export default Footer;
