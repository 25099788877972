var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import AppToolTip from '../wrappers/AppToolTip';
import LoadingButton from '@mui/lab/LoadingButton';
var buttonStyle = {
    padding: '16px 24px',
    height: 36,
    width: '100% !important',
};
var AppButton = function (_a) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var disabled = _a.disabled, onClick = _a.onClick, buttonText = _a.buttonText, tooltipText = _a.tooltipText, textColor = _a.textColor, variant = _a.variant, icon = _a.icon, styles = _a.styles, href = _a.href, download = _a.download, mainColor = _a.mainColor, loading = _a.loading, ref = _a.ref;
    return (_jsx(AppToolTip, { text: tooltipText !== null && tooltipText !== void 0 ? tooltipText : buttonText, children: _jsx(LoadingButton, { style: __assign(__assign({}, buttonStyle), styles), type: 'submit', href: href, ref: ref !== null && ref !== void 0 ? ref : null, download: download ? download : '', variant: variant !== null && variant !== void 0 ? variant : 'contained', color: mainColor !== null && mainColor !== void 0 ? mainColor : 'primary', size: 'small', disabled: disabled !== null && disabled !== void 0 ? disabled : false, endIcon: icon && icon, loading: loading !== null && loading !== void 0 ? loading : false, loadingPosition: 'center', onClick: function () {
                if (onClick != null)
                    onClick();
            }, children: !loading && (_jsx(Typography, { color: textColor, variant: 'button', style: { textTransform: 'none' }, children: buttonText })) }) }));
};
export default AppButton;
