var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography, useMediaQuery, useTheme, } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AppButton from '../buttons/AppButton';
import { useNavigate } from 'react-router-dom';
var headerSectionStyles = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    width: '100%',
    height: '90vh',
    padding: '30px 16px 10px 16px',
};
var socialIconsContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
};
var headerTop = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'transparent',
    width: 400,
};
var buttonContainer = {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
};
var titleContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
};
var headerSectionContent = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    width: '100%',
};
var HeaderSection = function () {
    var theme = useTheme();
    var navigate = useNavigate();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var handleNavigate = function (path, hash) {
        if (path.startsWith('http')) {
            window.location.href = path;
        }
        else {
            navigate(path);
            if (hash) {
                window.location.hash = hash;
            }
        }
    };
    var headerContainerStyles = function () {
        var styles = __assign({}, headerSectionStyles);
        if (isMobile) {
            styles = __assign(__assign({}, styles), { height: 'auto', paddingTop: 2 });
        }
        return styles;
    };
    return (_jsx(Box, { style: headerContainerStyles(), children: _jsx(Box, { style: headerSectionContent, children: _jsxs(Grid, { container: true, width: isMobile ? '100%' : '80%', spacing: 2, children: [_jsx(Grid, { size: { lg: 6, xs: 12 }, children: _jsxs(Box, { style: {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 20,
                                height: '100%',
                                width: '100%',
                                paddingTop: 20,
                                paddingRight: 30,
                            }, children: [_jsx("img", { alt: 'logo', style: { width: isMobile ? 200 : 400 }, src: 'https://strsharedeus.blob.core.windows.net/apps/capturequote/images/full_logo.png' }), _jsx(Typography, { variant: 'h2', fontSize: 50, fontWeight: 700, color: theme.palette.primary.main, children: "Your Library of Inspiration And Motivation." }), _jsx(Typography, { variant: 'h3', fontSize: 30, children: "Don\u2019t let powerful words slip away. Save, organize, and relive your favorite quotes or passages." }), _jsx(Box, { style: buttonContainer, children: _jsx(AppButton, { buttonText: 'Get The App', mainColor: 'secondary', textColor: theme.palette.common.white, onClick: function () {
                                            return handleNavigate('https://play.google.com/store/apps/details?id=codefronttech.capture.quote');
                                        } }) })] }) }), _jsx(Grid, { size: { lg: 6, xs: 12 }, children: _jsx(Box, { style: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                            }, children: _jsx("img", { alt: 'logo', style: {
                                    width: isMobile ? 330 : 600,
                                    background: 'transparent',
                                }, src: 'https://strsharedeus.blob.core.windows.net/apps/capturequote/images/screenshots-featured-graphic.svg' }) }) })] }) }) }));
};
export default HeaderSection;
