import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
import AppNavigation from './AppNavigation';
import Footer from './Footer';
import { useMediaQuery, useTheme } from '@mui/material';
// Styles for the layout
var layoutStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
    background: 'transparent',
};
var Layout = function () {
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var mainContentStyle = {
        flex: 1,
        paddingTop: isMobile ? 60 : 40, // Top and bottom padding to account for the fixed header and footer
    };
    return (_jsxs("div", { style: layoutStyle, children: [_jsx(AppNavigation, {}), _jsx("main", { style: mainContentStyle, children: _jsx(Outlet, {}) }), _jsx(Footer, {})] }));
};
export default Layout;
