import { jsx as _jsx } from "react/jsx-runtime";
import { Container } from '@mui/material';
import PageSection from '../components/sections/PageSection';
import HeaderSection from '../components/sections/HeaderSection';
var pageContainerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    padding: 0,
};
var Home = function () {
    return (_jsx(Container, { style: pageContainerStyle, maxWidth: false, children: _jsx(PageSection, { sectionId: 'home', children: _jsx(HeaderSection, {}) }) }));
};
export default Home;
