import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route, BrowserRouter } from 'react-router-dom'; // Import Route components
import Home from './pages/Home';
import Layout from './components/Layout';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Data from './pages/Data';
var App = function () {
    return (_jsx(BrowserRouter, { children: _jsx(Routes, { children: _jsxs(Route, { path: '/', element: _jsx(Layout, {}), children: [_jsx(Route, { index: true, element: _jsx(Home, {}) }), _jsx(Route, { path: '/privacy', element: _jsx(Privacy, {}) }), _jsx(Route, { path: '/terms', element: _jsx(Terms, {}) }), _jsx(Route, { path: '/data', element: _jsx(Data, {}) })] }) }) }));
};
export default App;
